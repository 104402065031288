@import "assets/fonts/_fonts.scss";

html {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100vh;
  width: 100vw;
  line-height: 1.7;
}

ul {
  list-style-type: none;
}

h1,
h2,
h3,
h4,
h5 {
  border-left: 2px solid rgb(0, 113, 206);
  padding-left: 1rem;
  text-align: left;
}

h1 {
  font-size: 1.4rem;
}

h2 {
  font-size: 1.2rem;
}

h3,
h4 {
  font-size: 1rem;
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

article a {
  color: black;
}

.component-wrapper {
  margin-bottom: 2rem;
}

.youtube-wrapper {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.button {
  border: 2px solid rgb(0, 34, 75);
  width: 70%;
  margin: auto;
  margin-top: 2rem;
  height: 50px;
  display: grid;
  align-items: center;
  justify-items: center;
  color: white;
  background-color: rgb(0, 113, 206);
  font-size: 1.2rem;
  animation: fadein 0.5s ease 1s both;
}

.button-delete {
  margin-left: 1rem;
  background-color: rgb(66, 11, 11);
  border: 2px solid rgb(98, 12, 12);
}

.button:disabled {
  color: rgb(192, 192, 192);
  background-color: #1b2431;
}

.content-wrapper {
  width: 90%;
  margin: auto;
  text-align: justify;
  font-size: 1.2rem;
}

.zitat {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  background-color: #1b2431;
  padding: 1rem 3rem;
  margin: 2rem 0;
  font-weight: 300;
  position: relative;
  color: white;
  animation: fadein 0.5s ease 4s both;

  .name {
    margin-top: 0.5rem;
    margin-left: 2rem;
    font-weight: bold;
  }
}

.center {
  font-weight: bold;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.quellen {
  font-size: 0.7rem;
  margin-top: 5rem;
}

.content-wrapper {
  img {
    margin: 2rem 0;
    max-width: 100%;
  }

  ul {
    margin: 0;

    li {
      margin-bottom: 1rem;
    }
  }
}

.img-container {
  display: flex;
  justify-content: center;
}

@media (min-width: 768px) {
  h1,
  h2,
  h3,
  h4,
  h5 {
    border-left-width: 3px;
  }

  h1 {
    margin-top: 2rem;
    font-size: 2rem;
  }

  h2 {
    margin-top: 3rem;
    font-size: 1.3rem;
  }

  h3 {
    font-size: 1.1rem;
  }

  .youtube-wrapper {
    flex-direction: column;
  }

  .content-wrapper {
    width: 50%;
  }

  .zitat-text {
    width: 40%;
    margin: auto;
  }

  .button {
    margin: 0;
    margin-top: 2rem;
    width: 25%;
    text-align: center;
    transition: all 0.3s;
    cursor: pointer;
    position: relative;
    background-color: #1b2431;

    span {
      z-index: 1;
    }

    &::before {
      position: absolute;
      left: 0;
      content: "";
      background-color: rgb(0, 113, 206);
      width: 0;
      height: 100%;
      transition: all 0.3s;
    }

    &:hover {
      &::before {
        width: 100%;
      }
    }

    &:disabled {
      &:hover {
        &::before {
          width: 0;
        }
      }
    }
  }

  .button-delete {
    margin-left: 1rem;
    background-color: rgb(66, 11, 11);
    &::before {
      background-color: rgb(182, 34, 34);
    }
  }
}

@keyframes fadein {
  from {
    transform: scale(1, 0);
  }

  to {
    transform: scale(1, 1);
  }
}
