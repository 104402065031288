@font-face {
        font-family: 'Roboto';
        src: url('Roboto-Regular.woff') format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
}

@font-face {
        font-family: 'Roboto';
        src: url('Roboto-Italic.woff') format('woff');
        font-weight: 400;
        font-style: italic;
        font-display: swap;
}

@font-face {
        font-family: 'Roboto';
        src: url('Roboto-Light.woff') format('woff');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
}

@font-face {
        font-family: 'Roboto';
        src: url('Roboto-Bold.woff') format('woff');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
}
